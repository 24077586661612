'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CurrencyConverter = function () {
  function CurrencyConverter() {
    _classCallCheck(this, CurrencyConverter);

    /**
     * @property {object} defaults                       - The default configuration for CurrencyConverter
     * @property {string} defaults.switcherSelector      - CSS Selector for dropdown which controls currency conversion
     * @property {string} defaults.priceSelector         - CSS Selector for elements containing prices
     * @property {string} defaults.shopCurrency          - Shop's currency (Currency which prices are printed as)
     * @property {string} defaults.defaultCurrency       - Theme's currency setting, or initial currency to show on the page
     * @property {string} defaults.displayFormat         - `money_with_currency_format` or `money_format`
     * @property {string} defaults.moneyFormat           - Shop's currency formatted using the display format
     * @property {string} defaults.moneyFormatNoCurrency - Shop's currency formatted without showing currency
     * @property {string} defaults.moneyFormatCurrency   - Shop's currency formatted showing currency
     */
    this.defaults = {
      switcherSelector: '[data-currency-converter]',
      priceSelector: '.money',
      shopCurrency: '',
      defaultCurrency: '',
      displayFormat: '',
      moneyFormat: '',
      moneyFormatNoCurrency: '',
      moneyFormatCurrency: ''
    };

    this.isInitialised = false;
  }

  _createClass(CurrencyConverter, [{
    key: 'init',
    value: function init(options) {
      if (!window.Currency || this.isInitialised) return;

      this.options = Object.assign({}, this.defaults, options);

      window.Currency.format = this.options.displayFormat;
      window.Currency.moneyFormats[this.options.shopCurrency].money_with_currency_format = this.options.moneyFormatCurrency;
      window.Currency.moneyFormats[this.options.shopCurrency].money_format = this.options.moneyFormatNoCurrency;
      window.Currency.currentCurrency = window.Currency.cookie.read() || this.options.defaultCurrency;

      this.isInitialised = true;
      this._current();
    }

    /**
     * Change the currency to a new currency using an ISO currency code
     *
     * @param newCurrency
     */

  }, {
    key: 'setCurrency',
    value: function setCurrency(newCurrency) {
      if (!this.isInitialised) return;

      this._convertAll(window.Currency.currentCurrency, newCurrency);
    }

    /**
     * Updates a price on the page to use the active Currency, and formatting
     *
     * @param priceEl {HTMLElement}
     */

  }, {
    key: 'update',
    value: function update(priceEl) {
      if (!this.isInitialised) return;

      var formattedPrice = window.Shopify.formatMoney(this._moneyValue(priceEl.innerHTML), this.options.moneyFormat);
      var attributes = priceEl.attributes;

      for (var attr = 0; attr < attributes.length; attr++) {
        var attribute = attributes[attr];

        if (attribute.name.indexOf('data-currency') === 0) {
          priceEl.setAttribute(attribute.name, '');
        }
      }

      priceEl.innerHTML = formattedPrice;
      priceEl.removeAttribute('data-currency');
      priceEl.setAttribute('data-currency-' + this.options.shopCurrency, formattedPrice);
      priceEl.setAttribute('data-currency', this.options.shopCurrency);

      this._convertEl(priceEl, this.options.shopCurrency, window.Currency.currentCurrency);
    }

    /**
     * Update the currency switcher to the current currency
     * @private
     */

  }, {
    key: '_current',
    value: function _current() {
      var switchers = document.querySelectorAll(this.options.switcherSelector);
      for (var i = 0; i < switchers.length; i++) {
        var switcher = switchers[i];

        var childrenEls = switcher.querySelectorAll('option');

        for (var _i = 0; _i < childrenEls.length; _i++) {
          var optionEl = childrenEls[_i];

          if (optionEl.selected && optionEl.value !== window.Currency.currentCurrency) {
            optionEl.selected = false;
          }

          if (optionEl.value === window.Currency.currentCurrency) {
            optionEl.selected = true;
          }
        }
      }

      this._convertAll(this.options.shopCurrency, window.Currency.currentCurrency);
    }

    /**
     * Converts formatted money to a number
     *
     * @param price {String}
     * @returns {Number}
     * @private
     */

  }, {
    key: '_moneyValue',
    value: function _moneyValue(price) {
      return parseInt(price.replace(/[^0-9]/g, ''), 10);
    }

    /**
     * Converts an individual price to the new format
     *
     * @param priceEl
     * @param oldCurrency
     * @param newCurrency
     * @private
     */

  }, {
    key: '_convertEl',
    value: function _convertEl(priceEl, oldCurrency, newCurrency) {

      // If the amount has already been converted, we leave it alone.
      if (priceEl.getAttribute('data-currency') === newCurrency) {
        return;
      }

      // If we are converting to a currency that we have saved, we will use the saved amount.
      if (priceEl.getAttribute('data-currency-' + newCurrency)) {
        priceEl.innerHTML = priceEl.getAttribute('data-currency-' + newCurrency);
      } else {
        // Converting to Y for the first time? Let's get to it!
        var cents = 0.0;
        var oldAmount = this._moneyValue(priceEl.innerHTML);
        var oldFormat = window.Currency.moneyFormats[oldCurrency][this.options.displayFormat];

        // Convert prices from float values to integers if needed, then convert
        if (oldFormat.indexOf('amount_no_decimals') !== -1) {
          cents = window.Currency.convert(oldAmount * 100, oldCurrency, newCurrency);
        } else if (oldCurrency === 'JOD' || oldCurrency === 'KWD' || oldCurrency === 'BHD') {
          cents = window.Currency.convert(oldAmount / 10, oldCurrency, newCurrency);
        } else {
          cents = window.Currency.convert(oldAmount, oldCurrency, newCurrency);
        }

        var newFormat = window.Currency.moneyFormats[newCurrency][this.options.displayFormat];
        var newFormattedAmount = window.Currency.formatMoney(cents, newFormat);

        priceEl.innerHTML = newFormattedAmount;
        priceEl.setAttribute('data-currency-' + newCurrency, newFormattedAmount);
      }

      priceEl.setAttribute('data-currency', newCurrency);
    }

    /**
     * Convert all prices on the page to the new currency
     *
     * @param oldCurrency
     * @param newCurrency
     * @private
     */

  }, {
    key: '_convertAll',
    value: function _convertAll(oldCurrency, newCurrency) {
      var priceEls = document.querySelectorAll(this.options.priceSelector);
      if (!priceEls) return;

      window.Currency.currentCurrency = newCurrency;
      window.Currency.cookie.write(newCurrency);

      for (var i = 0; i < priceEls.length; i++) {
        this._convertEl(priceEls[i], oldCurrency, newCurrency);
      }
    }
  }]);

  return CurrencyConverter;
}();

var CurrencyConverter$1 = new CurrencyConverter();

module.exports = CurrencyConverter$1;
